import "./Assets/css/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage/HomePage";
import Aboutus from "./Pages/Aboutus/Aboutus";
import ContactUsPage from "./Pages/ContactUsPage/ContactUsPage";
import CaseStudyPage from "./Pages/CaseStudyPage/CaseStudyPage";
import WhoWeAre from "./Pages/WhoWeAre/WhoWeAre";
import Partners from "./Pages/Partners/Partners";
import WhatWeDo from "./Pages/WhatWeDo/WhatWeDo";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import { Helmet } from "react-helmet";

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>Tech Sierra - Digital and IT solutions for
          business transformation</title>
        <meta name="description" content="Empower your team with cutting-edge IT staffing and tech recruitment solutions crafted by industry leaders. Seamlessly integrate top talent and unlock long-term success with our tailored tech services." />
        <meta name="keywords" content="Tech Sierra, IT Solutions, Staffing & Recruiting , Software company, Application Development, Software Development, Software Maintenance, Big Data Analytics, E-commerce Solutions, IT Outsourcing, Web Development, Mobile App Development, Top IT company in India, Collaboration Software, Software-as-a-Service (SaaS), Cloud-native Applications, Full Stack Development, Frontend Development, Backend Development,
        API Development, Data Visualization Tools, Quality Assurance (QA) Testing, Digital Marketing Software, Project Management Tools, API Integration Services, Supply Chain Management Software, Payment Processing Solutions, Document Management Systems (DMS), IT company in Mumbai" />
      </Helmet>
      <Routes>
        <Route name="Home Page" path="/" element={<HomePage />} exact />
        <Route
          name="About Us Page"
          path="/about-us"
          element={<Aboutus />}
          exact
        />
        <Route
          name="Contact Us Page"
          path="/talk-to-us"
          element={<ContactUsPage />}
          exact
        />
        <Route
          name="Case Study Page"
          path="/case-study"
          element={<CaseStudyPage />}
          exact
        />
        <Route
          name="Who We Are Page"
          path="/who-we-are"
          element={<WhoWeAre />}
          exact
        />
        <Route
          name="Partners Page"
          path="/partners"
          element={<Partners />}
          exact
        />
        <Route
          name="What We Do Page"
          path="/what-we-do"
          element={<WhatWeDo />}
          exact
        />
        <Route
          name="Contact Us"
          path="/talk-to-us"
          element={<ContactUsPage />}
          exact
        />
        <Route name="Error Page" path="/*" element={<ErrorPage />} exact />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
