import React, { useState, useEffect } from 'react'
import './WhoWeAre.css'
import WhoWeAreEvents from '../../Components/WhoWeAreEvents/WhoWeAreEvents';
import WhoWeAreCounter from "../../Components/WhoWeAreCounterScreen/WhoWeAreCounterScreen"
import Timeline from "../../Components/WhoWeAreTimeline/WhoWeAreTimeline"
import OurEmployeesWords from '../../Components/OurEmployeesWords/OurEmployeesWords';
import NewFooter from '../../Components/NewFooter/NewFooter'
import WWDHeader from '../WhatWeDo/WWDHeader/WWDHeader';
import { Helmet } from 'react-helmet';

function WhoWeAre() {

    const [scrollHeight, setScrollHeight] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            const scrollHeight = window.scrollY;
            setScrollHeight(scrollHeight);
        };
        window.addEventListener('scroll', handleScroll);
        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.scrollTo(0, 0)
        };
    }, []);

    return (
        <>
            <WWDHeader displayNone={scrollHeight > 3243 ? 'none' : ''} bgColor={'#080D27'} position={'fixed'} />
            <div className='whoWeAre'>
                <Helmet>
                    <title>Your Trusted IT Partner</title>
                    <meta name="description" content="We are a leading provider of comprehensive IT solutions designed to meet the diverse needs of businesses across industries. With a focus on innovation, reliability, and client satisfaction, we offer a wide range of services including software development, infrastructure management, cybersecurity, and cloud computing. Our team of experienced professionals is dedicated to understanding your unique challenges and goals, crafting customized solutions that drive efficiency, enhance productivity, and ensure the security of your digital assets. From startups to enterprises, we are committed to delivering exceptional service and empowering our clients to thrive in today's fast-paced digital landscape. Partner with us for reliable, scalable, and cost-effective IT solutions tailored to your business needs." />
                    <meta name="keywords" content="IT solutions, Trusted IT partner, Team, Join our team, services, Career development, Digital transformation, Software development,Cloud solutions, Work with us, Job vacancies, Career advancement, Employee benefits" />
                </Helmet>

                <div className="scroll-item">
                    <WhoWeAreCounter />
                </div>
                <div className="scroll-item">
                    <Timeline />
                </div>
                <div className="scroll-item">
                    <WhoWeAreEvents />
                </div>
                <div className="scroll-item">
                    <OurEmployeesWords />
                </div>
                <div className="scroll-item" id='getconsulation'>
                    <NewFooter />
                </div>
            </div>
        </>
    )
}

export default WhoWeAre
