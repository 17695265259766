import React, { useEffect } from 'react'
import Contact from '../../Components/NewFooter/NewFooter'
import Header from '../../Components/Header/Header';
import "./ContactUsPage.css";
import { Helmet } from 'react-helmet';

const ContactUsPage = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <div className='contact-us-page'>
      <Helmet>
                    <title>Get in Touch - Connect with Our IT Experts Today</title>
                    <meta name="description" content="Our Contact Us page serves as your gateway to unlocking the full potential of your digital journey. Whether you have questions about our services, need technical support, or are interested in partnering with us, our team of IT experts is here to assist you every step of the way. Fill out the form below, and one of our knowledgeable representatives will promptly get back to you. Alternatively, you can reach us via phone or email listed below. Let's start a conversation and explore how our customized IT solutions can propel your business forward in today's dynamic digital landscape." />
                    <meta name="keywords" content="Contact us, Get in touch, Reach out, Contact information, Contact form, Contact details, Contact options, Contact communication,Contact query, Contact inquiry, Contact assistance, Connect with us, Contact channels" />
                </Helmet>
    <Header
        bgColor={
          "rgb(8, 13, 39)"
        }      
      />
    <Contact/>
    </div>
  )
}

export default ContactUsPage;